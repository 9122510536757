import { AppKeySingles } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';
import { Phase } from '@pixels/universal/model/phase-model';

const FOLDER_NAME = 'singles-client';
const PROD_WEB_PUSH_KEY = 'BHEnfuO7AGRZ4Xah9hcmrU22J4b6_DiA22aPwrpmGmnYCucU7g2RFjc-PimUpeOSbL9rY7maqJ0s3VOaAIHQSaM';
const DEV_WEB_PUSH_KEY = 'BExKYP7jO6QZ_8Nd1dqnDselcriebewo9DyNdTJfjyYgF8QfHeCz1WP3y_mdWXOP4Gimz3xcEH53pQacEfLGwDU';
const PROD_AUTH_CALLBACK_URL = 'https://pixels-singles.firebaseapp.com/__/auth/handler';
const DEV_AUTH_CALLBACK_URL = 'https://pixels-singles-dev.firebaseapp.com/__/auth/handler';
const ProdFirebaseConfig = {
  apiKey: 'AIzaSyCnkO1-XB4K-2G9f5Ae7vHszODgsNJevdA',
  authDomain: 'pixels-singles.firebaseapp.com',
  projectId: 'pixels-singles',
  storageBucket: 'pixels-singles.appspot.com',
  messagingSenderId: '177461997453',
  appId: '1:177461997453:web:2908c611bbf358c3ee57c4',
  measurementId: 'G-BRJ2KCP6FZ'
};
const DevFirebaseConfig = {
  apiKey: 'AIzaSyDQLtIyBcy5N-EoVLaTD5XNOxaUAJx5kg0',
  authDomain: 'pixels-singles-dev.firebaseapp.com',
  projectId: 'pixels-singles-dev',
  storageBucket: 'pixels-singles-dev.appspot.com',
  messagingSenderId: '65303648064',
  appId: '1:65303648064:web:145f0d9247243b9d200ca5',
  measurementId: 'G-TV0HGE9X8K'
};
const PROD_TALK_PLUS_APP_ID = 'a0c8968c-0242-4a17-bdae-a6f769a2942b';
const DEV_TALK_PLUS_APP_ID = '618a8b13-f9f9-460e-abea-aaf7728569e4';

export const SinglesProjectMeta: ProjectMeta = {
  id: AppKeySingles,
  mainPrimaryColor: '#5891FF',
  folderName: FOLDER_NAME,
  publicPath: `dist/apps/${FOLDER_NAME}/browser`,
  supportPhases: [Phase.prod, Phase.dev],
  port: 8112,
  apiName: 'singles-api',
  apiPort: 5012,
  appId: 'io.pixelteam.singles',
  appStoreUrl: 'https://apps.apple.com/app/id6502037922',
  appName: '싱글즈',
  appNameEn: 'Singles',
  appSchemeName: 'Singles',
  testTargetUrl: 'https://test-singles.pixelteam.io',
  devTargetUrl: 'https://dev-singles.pixelteam.io',
  prodApi: 'https://singles-api.pixelteam.io',
  devApi: 'https://dev-singles-api.pixelteam.io',
  firebaseId: 'pixels-singles',
  testHostname: 'test-singles',
  clientSentryDsn: 'https://9ba543ee8991f6d11dad40858c46aabe@o190729.ingest.us.sentry.io/4507165493362688',
  prod: {
    deployPath: '/api/v1/project/9070/stage/10103/scenario/11295/deploy',
    targets: ['prod-02'],
    port: 8300,
    androidAppId: '1:177461997453:android:26a41bfa420b3dcbee57c4',
    iosAppId: '1:177461997453:ios:5c159251df082efdee57c4',
    iosEncodingAppId: 'app-1-177461997453-ios-5c159251df082efdee57c4',
    webAppId: '1:177461997453:web:2908c611bbf358c3ee57c4',
    kakaoAppKey: 'de6ed1e9b1fb9786ed0ed56a35ba0894',
    googleReversedId: 'com.googleusercontent.apps.177461997453-84nichjt1gk5ijkglmo6i4h9s5gqdb8v',
    webPushKey: PROD_WEB_PUSH_KEY,
    firebaseConfig: ProdFirebaseConfig,
    talkPlusAppId: PROD_TALK_PLUS_APP_ID,
    apiUrl: 'https://singles-api.pixelteam.io',
  },
  dev: {
    deployPath: '/api/v1/project/9070/stage/10104/scenario/11282/deploy',
    targets: ['dev-01'],
    port: 8301,
    androidAppId: '1:65303648064:android:1784f503f47988f4200ca5',
    iosAppId: '1:65303648064:ios:3693e4b4f46aa9b1200ca5',
    iosEncodingAppId: 'app-1-65303648064-ios-3693e4b4f46aa9b1200ca5',
    webAppId: '1:65303648064:web:145f0d9247243b9d200ca5',
    kakaoAppKey: '08051fb292be05129dc25d5aca5b75f1',
    googleReversedId: 'com.googleusercontent.apps.65303648064-qvdh83j7nfu0slcdkejkepm72o3fm862',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://dev-singles-api.pixelteam.io',
  },
  test: {
    androidAppId: '1:65303648064:android:f78dc4e4401ce853200ca5',
    iosAppId: '1:65303648064:ios:41fe3bb34460f383200ca5',
    iosEncodingAppId: 'app-1-65303648064-ios-41fe3bb34460f383200ca5',
    kakaoAppKey: '08051fb292be05129dc25d5aca5b75f1',
    googleReversedId: 'com.googleusercontent.apps.65303648064-0pbiiht4cmoot6e58arg2trivhb2sd4u',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://test-singles-api.pixelteam.io',
  },
  android: {
    admobAppId: 'ca-app-pub-4000507444081320~5752148149',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/3563515801',
  },
  ios: {
    admobAppId: 'ca-app-pub-4000507444081320~1621331447',
    admobBannerVideoChatAppId: 'ca-app-pub-4000507444081320/4787099578',
  },
  NSCameraUsageDescription: '카메라로 찍은 사진을 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSPhotoLibraryAddUsageDescription: '회원님이 싱글즈에 사진을 저장에 대한 액세스 권한을 사용합니다.',
  NSPhotoLibraryUsageDescription: '앨범에 있는 사진과 GIF를 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSCameraUsageDescriptionEn: 'You can attach a photo taken with your camera to a message or list it as your profile picture.',
  NSPhotoLibraryAddUsageDescriptionEn: 'Enable access for members to save photos to Singles.',
  NSPhotoLibraryUsageDescriptionEn: 'You can use photos and GIFs from your albums as attachments to messages or as your profile picture.',
  consoleLogo: `
███████╗██╗███╗   ██╗ ██████╗ ██╗     ███████╗███████╗
██╔════╝██║████╗  ██║██╔════╝ ██║     ██╔════╝██╔════╝
███████╗██║██╔██╗ ██║██║  ███╗██║     █████╗  ███████╗
╚════██║██║██║╚██╗██║██║   ██║██║     ██╔══╝  ╚════██║
███████║██║██║ ╚████║╚██████╔╝███████╗███████╗███████║
╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝╚══════╝╚══════╝
`
};
