import { isPlatformBrowser } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, inject, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, indexedDBLocalPersistence, initializeAuth, provideAuth } from '@angular/fire/auth';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, RouteReuseStrategy, withPreloading } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideNgIdle } from '@ng-idle/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { ANALYTICS_OPTIONS } from '@pixels/client/anayltics/analytics.model';
import { WINDOW } from '@pixels/client/dom/window-token';
import { authGuard } from '@pixels/client/guards/auth.guard';
import { checkPunishmentGuard } from '@pixels/client/guards/check-punishment.guard';
import { DEFAULT_GUARDS } from '@pixels/client/guards/guard-model';
import { instaChatConfigGuard } from '@pixels/client/guards/insta-chat-config.guard';
import { needUpdateInstaChatUserGuard } from '@pixels/client/guards/need-update-insta-chat-user.guard';
import { DEFAULT_LANGUAGE_TOKEN, SUPPORT_LANGUAGE_CODES_TOKEN } from '@pixels/client/i18n-util/i18n-util';
import { LocaleIdFactory } from '@pixels/client/i18n-util/local-id-factory';
import { API_URL_TOKEN } from '@pixels/client/injection-tokens/api-url-token';
import { CONTACT_US_PATH_TOKEN, PROXY_URL_TOKEN } from '@pixels/client/injection-tokens/common-link-tokens';
import { PHASE_TOKEN, VAPID_KEY_TOKEN } from '@pixels/client/injection-tokens/environment-token';
import { TALK_PLUS_APP_ID_TOKEN } from '@pixels/client/injection-tokens/talk-plus-app-id';
import { apiWrapperInterceptor } from '@pixels/client/interceptors/api-wrapper.interceptor';
import { environmentInterceptor } from '@pixels/client/interceptors/environment.interceptor';
import { tokenInterceptor } from '@pixels/client/interceptors/token.interceptor';
import { initLogger, LoggerService } from '@pixels/client/logger/logger.service';
import { AppStateService, migrateForage } from '@pixels/client/ngforage/app-state.service';
import { getRoutes } from '@pixels/client/one-on-one/app.routes';
import { PATH_MAP } from '@pixels/client/one-on-one/core/constant';
import { QUESTION_DETAIL_PATH_TOKEN } from '@pixels/client/pages/my-question/my-question.model';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { lazyLoadError } from '@pixels/client/router/lazy-load-error';
import { Lang, LANGUAGE_CODES_FOR_SCREENSHOTS } from '@pixels/universal/model/i18n/i18n.model';
import { Phase } from '@pixels/universal/model/phase-model';
import * as SentryAngular from '@sentry/angular';
import { DEFAULT_CONFIG, NgForageOptions } from 'ngforage';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { environment } from '../environments/environment';
import { AppI18nLoader } from './app-i18n-loader';
import { SinglesAppInfo } from './singles-app-info.service';

const isIOS = Capacitor.getPlatform() === 'ios';
const isNative = Capacitor.isNativePlatform();

export const appConfig: ApplicationConfig = {
  providers: [
    provideIonicAngular({ swipeBackEnabled: isIOS && isNative }),
    provideAnimations(),
    provideRouter(
      [
        {
          path: '',
          canActivate: isNative ? [...DEFAULT_GUARDS, instaChatConfigGuard, authGuard, checkPunishmentGuard, needUpdateInstaChatUserGuard] : [...DEFAULT_GUARDS, instaChatConfigGuard, checkPunishmentGuard, needUpdateInstaChatUserGuard],
          loadChildren: () => import('@pixels/client/one-on-one/pages/tabs/tabs.routes').then((m) => m.routes).catch(lazyLoadError),
        },
        ...getRoutes(environment.phase === Phase.local),
      ],
      withPreloading(QuicklinkStrategy)
    ),
    provideHttpClient(
      withFetch(),
      withInterceptors([apiWrapperInterceptor, tokenInterceptor, environmentInterceptor])
    ),
    provideFirebaseApp(_ => {
      const app = initializeApp(environment.firebase);
      if (isPlatformBrowser(inject(PLATFORM_ID))) {
        import('@angular/fire/analytics').then(({ initializeAnalytics }) => initializeAnalytics(app, ANALYTICS_OPTIONS));
      }

      if (isNative) {
        initializeAuth(app, { persistence: indexedDBLocalPersistence });
      }
      return app;
    }),
    provideAuth(_ => {
      const auth = getAuth();
      const lang = inject(TranslateService).getBrowserCultureLang();
      auth.languageCode = lang ?? Lang.EN;
      if (isPlatformBrowser(inject(PLATFORM_ID)) && !environment.production) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inject(WINDOW).signInWithCustomToken = async (customToken: string): Promise<any> => signInWithCustomToken(auth, customToken);
      }
      return auth;
    }),
    provideNgIdle(),
    importProvidersFrom(
      QuicklinkModule,
      TranslateModule.forRoot({ loader: { provide: TranslateLoader, useClass: AppI18nLoader } }),
      MatSnackBarModule,
      MatDialogModule,
    ),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: APP_INITIALIZER, useFactory: migrateForage, deps: [AppStateService], multi: true },
    { provide: APP_INITIALIZER, useFactory: initLogger, deps: [LoggerService], multi: true },
    { provide: LOCALE_ID, deps: [TranslateService], useFactory: LocaleIdFactory },
    { provide: DEFAULT_LANGUAGE_TOKEN, useValue: Lang.EN },
    { provide: SUPPORT_LANGUAGE_CODES_TOKEN, useValue: LANGUAGE_CODES_FOR_SCREENSHOTS },
    { provide: PHASE_TOKEN, useValue: environment.phase },
    { provide: TALK_PLUS_APP_ID_TOKEN, useValue: environment.talkPlusAppId },
    { provide: API_URL_TOKEN, useValue: environment.apiUrl },
    { provide: PROXY_URL_TOKEN, useValue: environment.proxyUrl },
    { provide: VAPID_KEY_TOKEN, useValue: environment.vapidKey },
    { provide: CONTACT_US_PATH_TOKEN, useValue: PATH_MAP.myQuestion.fullPath },
    { provide: QUESTION_DETAIL_PATH_TOKEN, useValue: PATH_MAP.myQuestionView.fullPath },
    { provide: DEFAULT_CONFIG, useValue: { name: `singles-${environment.phase}`, } as NgForageOptions },
    { provide: ErrorHandler, useValue: SentryAngular.createErrorHandler({ showDialog: false }) },
    { provide: AppInfo, useClass: SinglesAppInfo },
  ],
};
