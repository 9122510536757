import { inject, Injectable, signal } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Platform } from '@ionic/angular/standalone';
import { ONE_ON_ONE_ADMIN_MENUS } from '@pixels/client/pages/admin/admin.model';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppKeySingles } from '@pixels/universal/model/apps/app-types';
import { SinglesProjectMeta } from '@pixels/universal/model/apps/project-meta/singles-project-meta';

@Injectable({ providedIn: 'root' })
export class SinglesAppInfo extends AppInfo {
  public readonly appType = AppKeySingles;
  public readonly serviceName = _('@싱글즈@');
  public readonly appStoreUrl = signal(SinglesProjectMeta.appStoreUrl ?? '');
  public readonly playStoreUrl = signal(`https://play.google.com/store/apps/details?id=${SinglesProjectMeta.appId}`);

  public override readonly admobBannerVideoChatAppId = (inject(Platform).is('ios') ? SinglesProjectMeta.ios?.admobBannerVideoChatAppId : SinglesProjectMeta.android?.admobBannerVideoChatAppId) ?? '';
  public override readonly loginTitle = _('@싱글즈@');
  public override readonly loginDescription = _('@비혼주의_친구들_모두_모여라@');
  public override readonly loginAppIconUrl = 'https://cdn.pixelteam.io/pixels/app-icons/singles-app-icon.svg';
  public override readonly bannerPlaceHolder = _('@싱글즈_비혼_친구_만들기_소개팅@');
  public override readonly adminMenus = ONE_ON_ONE_ADMIN_MENUS;
}
