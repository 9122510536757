import { SinglesProjectMeta } from '@pixels/universal/model/apps/project-meta/singles-project-meta';
import { EnvironmentsConfig } from '@pixels/universal/model/firebase/environment.model';
import { Phase } from '@pixels/universal/model/phase-model';
import { PROD_PROXY_URL } from '@pixels/universal/model/proxy/proxy.model';

export const environment: EnvironmentsConfig = {
  production: true,
  apiUrl: SinglesProjectMeta.prod?.apiUrl ?? '',
  phase: Phase.prod,
  firebase: SinglesProjectMeta.prod?.firebaseConfig,
  talkPlusAppId: SinglesProjectMeta.prod?.talkPlusAppId ?? '',
  vapidKey: SinglesProjectMeta.prod?.webPushKey ?? '',
  useEmulators: false,
  proxyUrl: PROD_PROXY_URL
};
